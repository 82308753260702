<template>
  <div class="overflow_auto">
    <div class="color">
      <div class="box_content text-center">
          <!-- <div class="mb-5">
            <img src="@/assets/img/logo/logo.png" class="mx-auto mb-5" alt="" height="100">
          </div> -->
          <div class="text-start">
            <div class="mb-1 mt-5 d-flex align-items-center justify-content-center text-white opacity-80">
              <i class="fas fa-coin small mr-2"></i>
              <div class="small">{{$t("dashboard.17")}}</div>
            </div>
            <div class="mb-4 text-white">
              <h3>{{ total_usd }} USD</h3>
            </div>
          </div>
          
          <div class="progress my-2" v-if="coinTestList.length>0">
            <div class="progress-bar d-flex flex-column align-items-center text-dark" 
            role="progressbar" 
            :style="{ width: calculateWidth(item.usd_balance) + '%',  background: 'rgba(255, 255, 255, ' + calculateOpacity(index) + ')' }" 
            v-for="(item,index) in coinTestList" :key="index">
              <b style="line-height:1;" v-if="item.usd_balance ==0">{{ 0+ '%' }}</b>
              <b style="line-height:1;" v-if="item.usd_balance !=0">{{ Math.round(calculateWidth(item.usd_balance)) + '%' }}</b>
              <span class="text-truncate w-100 px-2 mt-2" style="line-height:1;">{{item.name}}</span>
            </div>
            <!-- <div class="progress-bar bg-primary d-flex flex-column" role="progressbar" style="width: 30%; opacity:.7" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
            <div class="progress-bar bg-primary d-flex flex-column" role="progressbar" style="width: 20%; opacity:.4" aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div> -->
          </div>
      </div>
    </div>
    <div class="coin_wrap">
      <!-- <div class="banner" @click="bannerClick">
        <span>{{$t('banner.1')}}<i class="far fa-hand-pointer ml-2"></i></span>
      </div> -->
      <ul class="pl-0 coin_list">
        
        <!-- <li class="float_box mb-3" @click="$router.push({name: 'Staking'})">
          <div class="box_title">  
            <h6 class="mb-0"><i class="far fa-chart-line mr-2"></i>STAKING</h6>
            <div class="status">
              <span>{{$t("staking.11")}} {{staking.total}}{{$t("dashboard.3")}} {{$t("staking.10")}}</span>
              <i class="fas fa-chevron-right"></i>
            </div>
          </div>
          <div class="box_content w-100">
              <div class="d-flex justify-content-between align-items-center">
                  <label style="color: #541792;">{{$t('staking.17')}} {{$t("staking.12")}}</label>
                  <p>{{yet.begin}} {{$t("dashboard.3")}}</p>
              </div>
              <div class="gauge_box my-2">
                  <div class="gauge" :style="'width :'+ (((yet.begin/yet.total)*100)|0) +'%;'"></div>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                  <label>{{$t("staking.13")}}</label>
                  <p>{{staking.ing}} {{$t("dashboard.3")}}</p>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                  <label>{{$t("staking.14")}}</label>
                  <p>{{staking.confirm}} {{$t("dashboard.3")}}</p>
              </div>
              <div class="d-flex justify-content-between align-items-center">
                  <label>{{$t("staking.15")}}</label>
                  <p>{{staking.succ}} {{$t("dashboard.3")}}</p>
              </div>
          </div>
        </li> -->
        <!-- <li class="float_box p-4" style="background-color:#45445e; background:linear-gradient(135deg,rgb(44 130 203),#3756da,#632cb7);" @click="nftMove()">
            <div class="d-flex flex-grow-1 align-items-center">
              <div class="text-white">
                <p class="h4">BISKIT NFT</p>
              </div>
            </div>
        </li> -->
        <!-- <hr class="cutting_line"> -->
        
        <div class="search_wrap d-flex justify-content-between mt-4 mb-3">
          <div class="search_input" style="width: calc(100% - 45px);">
            <i class="far fa-search position-absolute"></i>
            <input type="search" v-model="search" class="d-flex p-1" placeholder="Search Coin">
          </div>
          <div class="d-flex align-items-center justify-content-center set_btn" @click="coinSettingLocation">
            <i class="fas fa-cog"></i>
          </div>
        </div>

        <li class="float_box p-4" v-for="(coin, index) in postList" :key="index" @click="coinMove(coin.status, coin.net, coin.symbol)">
            <div class="info mb-3">
              <span class="icon mr-3">
                <img :src="coin.img" alt="icon" style="width: 100%;">
              </span>
              <span>{{coin.name}}</span>
              <span class="lock" v-if="coin.lock_balance > 0"><i class="far fa-solid fa-lock"></i> LOCK Balance : {{coin.lock_balance}} </span>
            </div>
            <div class="retained flex flex-column">
              <b>{{coin.balance}} {{coin.symbol}}</b>
              <span class="main_txt flex align-items-center justify-content-between">
                <span>= {{usdCalc(coin.symbol, coin.balance)}} USD</span>
                <span>{{coin.symbol}} 1 = {{rateCheck(coin.symbol)}} USD</span>
              </span> 
            </div>
        </li>

        <hr class="cutting_line">

        <li class="float_box notice_box d-block">
          <div class="box_title py-3 px-4 bg-primary text-white">  
            <h6 class="mb-0">{{$t("dashboard.10")}}</h6>
            <div class="status">
              <span class="font-weight-normal"><router-link class="text-white" :to="`/${$i18n.locale}/page/notice-list`">
                {{$t("dashboard.11")}}
              </router-link></span>
            </div>
          </div>
          <ul class="board_tit_box px-4 py-3">
            <li v-for="(item,index) in notice_list.slice(0,1)" :key="index" class="py-2">
              <router-link :to="`/${$i18n.locale}/page/notice/${item.no}`">
                  <div class="flex flex-column">
                    <p class="tit">{{item.title}}</p>
                    <p class="date">{{item.date}}</p>
                  </div>
                  <i class="far fa-chevron-right"></i>
              </router-link>
            </li>
            <li v-if="notice_list.length == 0" class="pb-0">
              <div class="empty_data text-center">
                <small class="pale_txt">{{$t("dashboard.18")}}</small>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <div class="footer mt-5">
          ©{{new Date().getFullYear()}} {{$store.state.FOOTER.LOGO}} CO,. LTD. All rights reserved.<br>
          <!-- CEO {{$store.state.FOOTER.CEO}}<br> -->
          <!-- Company Address : {{$store.state.FOOTER.ADDRESS}} -->
          https://goldnrush.com
      </div>
    </div>
    <div>
      <div class="popup_box" :class="{on : index == popup.length-1}" v-for="(item, index) in popup.slice().reverse()" :key="index">
        <div class="popup" v-if="item.active">
            <div class="dimm" @click="item.active = false, addClassOn(index)"></div>
            <div class="content_box">
              <p class="top_tit">{{item.title}}</p>
              <i class="fal fa-times close_icon" @click="item.active = false, addClassOn(index)"></i>
              <div class="content">
                <div class="tit_box">
                  <h5>NOTICE</h5>
                </div>
                <div class="txt_box">
                  {{item.description}}
                </div>
              </div>
              <span class="nomore_btn" @click="PopupNon(item),addClassOn(index)"><i class="fal fa-check-circle"></i> {{$t("dashboard.12")}}</span>
            </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default{
  data(){
    return{
      coinTestList:[],
      loginToken: this.$store.state.loginToken,
      lang: this.$route.params.lang,
      totalBalnce: 0,
      coinList:[],
      coinBalance: [],
      search: '',

      staking : {
        total: 0,
        confirm: 0,
        ing: 0,
        begin: 0,
        done: 0,
        succ: 0
      },
      yet:{
        total: 0,
        begin: 0
      },

      popup: [],
      popupNon: [],
      total_usd : '',
      rate: [],
      notice_list: [],
      nft :{balance:1}
    }
  },
  computed: {
    postList() {
      let list = [];
      
      for( let i=0; i<this.coinList.length; i++ ){
        if( this.coinList[i].symbol == 'EVOL'){
          list.unshift(this.coinList[i])
        }else{
          list.push(this.coinList[i]);
        }
      }

      return list.filter(post => {
        return post.name.toLowerCase().includes(this.search.toLowerCase())
      })

      // return this.coinList.filter(post => {
      //   return post.name.toLowerCase().includes(this.search.toLowerCase())
      // })
    }
  },
  mounted() {
    if(this.$store.state.login){
      this.Token_Check();
    }
  },
  methods: {
    calculateOpacity(index) {
      // 데이터 개수에 따라 각 항목의 opacity를 계산하여 반환합니다.
      
      const totalItems = this.coinTestList.length;

      return (totalItems - index) / totalItems;
    },

    calculateWidth(balance) {
      // 전체 밸런스의 합을 구합니다.

      const totalBalance = this.coinTestList.reduce((sum, item) => sum + item.usd_balance , 0);

      if(totalBalance ==0){
        this.total_usd = 0;
        const totalItems = this.coinTestList.length;
        let per = 100 / totalItems;
        return per;
      }else{
        const str = String(totalBalance);

        this.total_usd = str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');

        // 밸런스의 비율을 계산하여 퍼센티지로 반환합니다.
        return (balance / totalBalance) * 100;
      }
      
    },
    nftMove : function(){
      this.$router.push(`/${this.$i18n.locale}/page/nft`);
    },
    maintainLogin: function(){
        const idx = this.$store.state.token;
        var broswerInfo = navigator.userAgent;
            
        if(broswerInfo.indexOf("HYBRID_APPLICATION")>-1){
            try{
                window.myHybrid.savePhoneToken(idx);
            }catch(e) {}  
            this.$store.dispatch('setLoginToken');     

        } else if(broswerInfo.indexOf("HYBRID_IOS")>-1) {
            try{
                window.webkit.messageHandlers.savePhoneToken.postMessage(idx);
            }catch(e) {
                this.$alert("accessRequest OIError : "+e);
            }
            this.$store.dispatch('setLoginToken');            
        }
    },
    Token_Check: function(){
        const api = this.$store.state.baseURL;
        this.$http.post(`${api}/member/wallet/token/check`, {}).then(
        res => {
            if (res.status == 200) {
              const code = res.data.code;
              if(code == '200'){   
                this.MemDashbordCoinInfo();

                this.StakingInfo();
                this.StakingYetInfo();
                this.GetNotice();
                this.PopupNonCheck();
                if(this.loginToken){
                  this.maintainLogin();
                }
              }else if(code == '9999'){
                this.$alert(this.$t('signout.1')).then(()=>{
                    this.$store.dispatch('SETLOGOUT').then(()=>{
                        this.$router.push({name:'Signin'});
                    });
                })
              }
            }
        })
    },
    MemDashbordCoinInfo() {
        const api = this.$store.state.baseURL;
        this.$http.post(`${api}/member/setting/coin/list`, {}).then(
        res => {
            if (res.status == 200) {
                if (res.data.code == '200') {
                    const info = res.data.info;
                    this.WalletInfo(info);
                    this.CoinRate();  
                }
            }
        })
    },
    WalletInfo(set_coin) {
        const api = this.$store.state.baseURL;
        this.$http.post(`${api}/member/wallet/wallet_info_list/dashboard`, {set_coin}).then(
        res => {
            if (res.status == 200) {
                if (res.data.code == '200') {
                  this.coinList = res.data.info;
                  // this.coinTestList = res.data.info;

                  let coinList = [];
                  for (const el of res.data.info) {
                    const name = el.name;
                    const balance = el.balance;
                    const usd_balance = 0;
                    const symbol = el.symbol
                    coinList.push({name :symbol,balance,usd_balance});
                  }
                  this.coinTestList = coinList;
                }
            }
        }).finally(() => {          
          for(const [index, el] of this.coinList.entries()){
            
            this.CoinBalanceData(el.symbol, index);
            
            if(el.lock == 'Y') this.CoinLockData(el.symbol, index);
          }
        })
    },
    CoinUsdBalance(symbol,index){
      const balance = this.coinList[index].balance;

      const rate = this.rate;
      for(var i=0; i<rate.length; i++){
        if(symbol == rate[i].symbol) {
          
          
          this.coinTestList[index].usd_balance = parseFloat((parseFloat(balance) * parseFloat(rate[i].usd)).toFixed(2))
          const str = String((parseFloat(balance) * parseFloat(rate[i].usd)).toFixed(2));
          const price = str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
          
          return price; 
          
        }
      }
    },
    CoinBalanceData(symbol,index){
      const api = this.$store.state.baseURL;
      this.$http.post(`${api}/member/wallet/wallet_info_balance`, {symbol}).then(
      res => {
          if (res.status == 200) {
              if (res.data.code == '200') {
                this.coinList[index].balance = res.data.balance;
                
                this.CoinUsdBalance(symbol,index);
              }
          }
      })
    },
    CoinLockData(symbol,index){
      const api = this.$store.state.baseURL;
      this.$http.post(`${api}/member/wallet/lock/check`, {symbol}).then(
      res => {
          if (res.status == 200) {
              if (res.data.code == '200') {
                this.coinList[index].lock_balance = res.data.lock;
              }
          }
      })
    },
    CoinRate() {
      const api = this.$store.state.baseURL;
      this.$http.post(`${api}/member/wallet/rate`, {}).then(
      res => {
          if (res.status == 200) {
              if (res.data.code == '200') {
                this.rate = res.data.rate;
              }
          }
      })
    },
    rateCheck(symbol){
      const rate = this.rate;
      for(var i=0; i<rate.length; i++){
        if(symbol == rate[i].symbol) return rate[i].usd;
      } 
    },
    usdCalc(symbol, value){
      const rate = this.rate;
      for(var i=0; i<rate.length; i++){
        if(symbol == rate[i].symbol) { const str = String((parseFloat(value) * parseFloat(rate[i].usd)).toFixed(2));
          const price = str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,'); return price; 
        }
      }
    },
    StakingInfo() {
        const api = this.$store.state.baseURL;
        this.$http.post(`${api}/member/staking/dashboard`, {}).then(
        res => {
            if (res.status == 200) {
                if (res.data.code == '200') {
                  this.staking = res.data.staking;
                }
            }
        })
    },
    StakingYetInfo() {
        const api = this.$store.state.baseURL;
        this.$http.post(`${api}/member/staking/yet/data`, {}).then(
        res => {
            if (res.status == 200) {
                if (res.data.code == '200') {
                  this.yet = res.data.staking;
                }
            }
        })
    }, 
    coinMove(status, net, symbol) {
      if(status == "Y"){
        this.$router.push(`/${this.$i18n.locale}/page/coin/${net}/${symbol}`);
      }
      else{
        this.$alert(this.$t('dashboard.16'));
      }
    },
    // 팝업관련
    GetNotice() {
        const lang = this.lang;
        const api = this.$store.state.baseURL;
        this.$http.post(`${api}/member/board/notice/list`, {lang}).then(
        res => {
            if (res.status == 200) {
                if (res.data.code == '200') {
                    this.notice_list = res.data.list
                }
            }
        })
    },  
    GetNoticePopup() {
        const lang = this.lang;
        const popup = this.popupNon;
        const api = this.$store.state.baseURL;
        this.$http.post(`${api}/member/board/notice/popup`, {lang, popup}).then(
        res => {
            if (res.status == 200) {
                if (res.data.code == '200') {
                    res.data.list.forEach(el => {
                      this.popup.push({ no: el.no, title: el.title, description: el.memo, active: true });
                    });
                }
            }
        })
    },
    PopupNonCheck() {
        const api = this.$store.state.baseURL;
        this.$http.post(`${api}/member/board/notice/popupNoneChk`, {}).then(
        res => {
            if (res.status == 200) {
                if (res.data.code == '200') {
                  this.popupNon = res.data.non;
                }
            }
        }).finally(() => {
          this.GetNoticePopup();
        })
    },
    PopupNon(item) {
        const api = this.$store.state.baseURL;
        const no = item.no;
        this.$http.post(`${api}/member/board/notice/popupNone`, {no}).then(
        res => {
            if (res.status == 200) {
                if (res.data.code == '200') {
                  item.active = false;
                }
            }
        })
    },
    addClassOn(index){
      var popupBox = document.querySelectorAll(".popup_box")
      if( popupBox[index-1] ){
        popupBox[index].classList.remove("on")
        popupBox[index-1].classList.add("on")
      }else{
        popupBox[index].classList.remove("on")
      }
    },
    bannerClick(){
        this.$router.push(`/${this.$i18n.locale}/page/package`);
    },
    coinSettingLocation() {
        this.$router.push(`/${this.$i18n.locale}/page/coin`);
    }
  },
  filters: {
      currency: function(value){
          var num = new Number(value);
          return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
      }
  }
}
</script>

<style scoped>

.coin_wrap{
  background-color:#F5F6FA;
  padding: 1em;
  min-height: calc(100vh - 250px);
}
.float_box{
  position: relative;
  width: 100%;
  border-radius: 12px;
  background-color: #fff;
  min-height: 100px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-shadow: 0px 3px 2px 0 rgb(0 0 0 / 5%);
  margin-bottom: 16px;
}

.color{
  box-shadow: 0px 5px 10px 3px #00000008;
  position: relative;
  min-height: 200px;
  /* padding-top: 1em; */
  background: #4b67f9;
  /* background: linear-gradient(to bottom, #9070af, #00ada9 80%);
  background: linear-gradient(to top, #24243e 5%, #302b63 75%,rgb(15 12 41 / 0.6)); */
  /* background: linear-gradient(to top, #5b87ad, #8774af); */
  border-radius: 0 0 30px 30px;
}
.wallet{
  background: #4B67F9;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #363795, #4B67F9);
  background: linear-gradient(to right, #363795, #4B67F9); 
  color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 130px;
}
.wallet p{
  font-weight: 300;
  /* font-size: 14px; */
}
.wallet h2{
  font-weight: 700;
}
.box_title{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  font-size: 13px;
  border-bottom: 1px solid #eee;
}
.box_title h6{
  font-weight: 600;
}
.box_title h6 i{
  background: #4B67F9;  /* fallback for old browsers */
  background: linear-gradient(225deg,#61ade0,#6d27be);
  /* background: transparent; */
  border-radius: 6px;
  color: #fff;
  padding: 8px;
  font-size: 1em;
}
/* .box_content{
  padding: 1em 1em;
  box-sizing: border-box;
}
.box_content p{
  font-weight: 500;
  margin-bottom: 00px;

  color: #a9c1da;
  font-size: 12px;
  width: calc(100% - 80px);
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: normal;
}
.box_content label{
  font-size: 13px;
  margin-bottom: 0;
  color: #bbb;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  text-align: center;
  padding: 4px 10px;
  color: #fff;
  margin-right: auto;
} */

.box_content{
    padding: 1em 1em;
    box-sizing: border-box;
}
.box_content p{
    font-weight: 500;
    margin-bottom: 5px;
}
.box_content label{
    font-size: 12px;
    margin-bottom: 0;
    color: #bbb;
}

.box_content .fa-copy{
    font-size: 12px;
    color: #87c3ff;
    margin-left: 10px;
}
.status span{
  color: #4B67F9;
  font-weight: 600;
  font-size: 15px;
}

.status i {
  /* color: #988cca; */
  margin-left: 10px;
}

.notice_box .status span a{
  color: #171c61;
}
.notice_box .box_title, .board_tit_box{
  width : 100%;
}
.notice_box .box_title, .board_tit_box li{
  border-bottom: 0;
}
.notice_box .box_title, .board_tit_box i{
  font-size: 11px;
}
.notice_box .board_tit_box .tit{
  /* font-size: 14px; */
  font-size: 0.9em;
  font-weight: 400;
}
.notice_box .board_tit_box .date{
  /* font-size: 12px; */
  font-size: 0.8em;
}

.coin_list{
  /* transform: translateY(-50px); */
}
.coin_list .info{
  width: 100%;
}
.coin_list .icon{
  position: absolute;
  right: 5px;
  top: 25px;
  width: 32px;
  height: 32px;
}
.coin_list .icon img{
  height: 32px;
  object-fit: contain;
}
.coin_list .retained{
  width: 100%;
}
.coin_list .retained span{
  font-size: 12px;
}
  .popup{
    width: 100%;
    height: 100vh;
    min-height: 500px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 150;
    overflow-y: auto;
  }
  .popup .dimm{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .popup_box{
    /* display: block; */
    display: none;
  }
  .popup_box.on{
    display: block;
  }
  .popup_box:first-of-type .dimm{
    background-color: rgba(0, 0, 0, 0.5);
  }
  .popup .content_box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 500px;
    /* border: 1px solid #fff */
  }
  .popup .content{
    position: relative;
    width: 100%;
    height: calc(500px - 90px);
    margin-top: 40px;
    /* background-color: #495da8; */
    background-color: #fff;
    /* border-radius: 10px; */
    overflow: hidden;
    
  }
  .popup .close_icon{
    color: #fff;
    font-size: 25px;
    position: absolute;
    top: 10px;
    right: 10px;

  }
  .popup .nomore_btn{
    color: #fff;
    display: inline-block;
    transform: translate(10px,10px);
    font-size: 15px;
  }
  .popup .tit_box{
    /* background-color: #495da8; */
    color: #495da8;
    height: 70px;
    text-align: center;
    font-family: 'Fugaz One', sans-serif;
    font-weight: 600;
    letter-spacing: 2px;
  }
  .popup .tit_box h5{
    font-size: 30px;
    font-weight: normal;
    line-height: 80px;
    text-overflow: ellipsis;
  }
  .popup .txt_box{
    text-align: center;
    word-break: break-all;
    padding: 10px 25px;
    overflow-y: auto;
    height: calc(100% - 100px);
    white-space: pre-line;
    font-size: 15px;
    line-height: 1;
  }

  .popup .top_tit{
    font-size: 14px;
    position: absolute;
    top: 8px;
    left: 0;
    color: #fff;
    font-weight: normal;
    max-width: calc(100% - 30px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
    margin: 0;
    display: inline-block;
    padding: 2px 10px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 20px;
  }
  .total_balance{
    font-size: 2.5em;
  } 
  .banner{
    position: relative;
    width: 100%;
    border-radius: 12px;
    background-size: 300%;
    background: linear-gradient(to left, #4B67F9, #20659c);
    /* background: linear-gradient(90deg,#212454d6 0%,#4f54bb 10%,#212454d6 20%);
    animation: gradient-move 3s infinite ease;
    animation-delay: 2s;
    transition: background-position; */
    min-height: 60px;
    overflow: hidden;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    box-shadow: 0px 3px 2px 0 rgb(0 0 0 / 5%);
    transform: translateY(-50px);
    margin-bottom: 1em;
    text-align: center;
    justify-content: center;
    font-weight: 600;
    text-shadow: 2px 2px 0px #20659c;
    cursor: pointer;
  }
  .banner span{
    position: relative;
    z-index: 2;
  }
  .banner::after{
    content: "";
    display: block;
    background: linear-gradient(90deg,#21245400 0%,#2a80b9 10%,#21245400 20%);
    animation: gradient-move 3s infinite ease;
    animation-delay: 2s;
    transition: background-position;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .search_input{
    /* background-color: #ddf0fb; */
    /* background-color: #bbdbec; */
    /* border: 1px solid #ddd; */
    border-bottom: 1px solid #ddd;
    /* border-radius: 11px; */
    /* background-color: #fff; */
    /* border: none; */
    position: relative;
    /* box-shadow: 0px 3px 2px 0 rgb(0 0 0 / 5%); */
  }
  .search_input>i{
    font-size: 14px;
    transform: translate(50%,-50%);
    left: 4px;
    top: 50%;
  }
  .search_input input{
    border: none;
    background-color: transparent !important;
    outline: none;
    width: calc(100% - 30px);
    margin-left: 30px;
  }
  .set_btn{
    /* border: 2px solid #c1c1c1; */
    border-radius: 50%;
    width: 35px;
    height: 35px;
    /* background-color: #fff; */

    
    /* box-shadow: 0px 3px 2px 0 rgb(0 0 0 / 20%); */
    color: #787878;
  }
  .set_btn>i{
    font-size: 14px;
    padding: 5px;
    margin-right: 1px;
    margin-top: 1px;
  }
@keyframes gradient-move{
  0%{
    /* background-position: 0; */
    left: -20%;
  }
  100%{
    /* background-position: 100px; */
    left: 100%;
  }
}

.lock {
  display: block;
  font-size: 13px;
  font-weight: 500;
  color: #ff7b7b;
}

.drop_shadow{
  filter: drop-shadow(2px 4px 6px rgba(0,0,0,0.3));
}

.go_btn{
  border-color: #bfbddb!important;
  color: #bfbddb;
  font-size: 12px;
}

.w-90{
  width: 90%;
}

.bg-primary,.badge-primary{
  background-color: #4B67F9 !important;
}
.progress{
  height: 50px;
  border-radius: 16px;
  background-color: transparent !important;
}

.opacity-80{
  opacity: 0.8;
}
</style>
